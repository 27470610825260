<template>
  <nav class="docs-nav">
    <h3>Navigation</h3>
    <ul>
      <li><router-link to="/docs">Introduction</router-link></li>
      <li><router-link to="/docs/getting-started">Getting Started</router-link></li>
      <li><router-link to="/docs/cheat-sheet">Syntax &amp; Features Cheat Sheet</router-link></li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.docs-nav {
  background: var(--c-bg-shade);
  padding: 1rem;
  border-radius: 5px;
  margin: 1rem 0;

  h3 {
    font-weight: 300;
    font-size: 24px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      display: block;
      margin: 0;

      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 5px;

        &.router-link-exact-active {
          color: var(--c-txt-emphasis);
          font-weight: 600;
          cursor: default;
          padding-left: 2ch;

          &:hover {
            color: var(--c-txt-emphasis);
          }
        }
      }
    }
  }
}
</style>
