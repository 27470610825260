<template>
  <a class="external-link" :href="href" target="_blank">
    <span class="text">
      <slot v-if="$slots.default"/>
      <template v-else>{{ href }}</template>
    </span>
    <font-awesome-icon class="icon" icon="external-link-alt" />
  </a>
</template>

<script>
export default {
  name: 'ExtLink',
  props: { href: String },
}
</script>

<style lang="scss" scoped>
.external-link {
  .icon {
    font-size: 0.8em;
    margin-left: 1ch;
    position: relative;
    top: -2px;
    opacity: 70%;
  }
}
</style>
