<template>
  <article id="getting-started">
    <docs-nav />
    <header>
      <h2>Getting Started</h2>
      <p class="lead">This guide will run you through the key features of Conlang Workbench, and provide some basic examples to get you started with creating your own conlang using the workbench.</p>
    </header>
    <section id="contents">
      <h3>Contents</h3>
      <ol class="table-of-contents">
        <li><a href="#prerequisites">Before you begin...</a></li>
        <li><a href="#accounts">Creating an Account</a></li>
        <li><a href="#projects">Creating a New Project</a></li>
        <li><a href="#editor">The Editor</a></li>
        <li><a href="#languages-and-milestones">Languages &amp; Milestones</a></li>
        <li><a href="#words">Defining Words</a></li>
        <li>
          <a href="#rules">Defining Sound Changes</a>
          <ol>
            <li><a href="#timeline">Sound Changes Over Time</a></li>
          </ol>
        </li>
        <li><a href="#next-steps">Next Steps</a></li>
      </ol>
    </section>
    <section id="prerequisites">
      <h3>1. Before you begin...</h3>
      <p>There are a few important things you may wish to know before you start learning how to use Conlang Workbench. This guide assumes only fairly minimal knowledge of things like IPA (International Phonetic Alphabet), phonological rule notation, and so on, but understanding these things may help with your understanding as you go through the guide.</p>
      <p>Here are some great links that cover the basics:</p>
      <ul>
        <li><ext-link href="http://westonruter.github.io/ipa-chart/keyboard/">IPA Chart &amp; Keyboard (westonruter.github.io)</ext-link></li>
        <li><ext-link href="https://www.ipachart.com/">IPA Chart with audio clips (ipachart.com)</ext-link></li>
        <li><ext-link href="http://grammar.ucsd.edu/courses/lign150/lgchng.pdf">Types of Sound Change (PDF)</ext-link></li>
        <li><ext-link href="https://www.reddit.com/r/conlangs/comments/44lb5c/ccc_07022016_adv02_sound_change_part_13/">A Course on Sound Changes (r/conlangs)</ext-link></li>
        <li><ext-link href="https://en.wikipedia.org/wiki/Phonological_rule">Phonological Rules (Wikipedia)</ext-link></li>
      </ul>
    </section>
    <section id="accounts">
      <h3>2. Creating an Account</h3>
      <p>In order to create a new project and edit it in the workbench, you need to create an account. This allows you to save your progress without having to copy+paste your sound changes/word list every time you want to regenerate the lexicon, and keeps your project safe from other users.</p>
      <p>If you already have an account, you can skip this step.</p>
      <p>To create an account, or login to an existing account, click the "Login / Sign-Up" button in the top-right corner of the screen. This will open a form where you can enter the necessary details and create your account.</p>
      <p>Once your account has been created, you'll be able to see your username in the top-right corner of the screen. You can find the "Sign Out" button by clicking your username.</p>
      <p class="hint"><b>Please Note:</b> During the public beta testing period, the account creation process has been simplified to make it easier to test the application. <strong>After the testing period, accounts created this way will be wiped.</strong></p>
    </section>
    <section id="projects">
      <h3>3. Creating a New Project</h3>
      <p>Projects are how you organise your conlangs in the workbench. To create a new project, navigate to your <router-link to="/projects" target="_blank">Projects list</router-link>, and click the <font-awesome-icon icon="plus-square" /> icon at the top of the list.</p>
      <p>When your project has been created, you'll be thrown right into the editor, and you can start working on your conlang. Any changes you make in the editor will be saved automatically. When you want to come back to your project, you can find it in the projects list.</p>
    </section>
    <section id="editor">
      <h3>4. The Editor</h3>
      <p>The editor is where you can view and work on your project. It consists of several tabs which can be selected using the tab menu on the left side of the screen, or at the bottom of the screen on mobile devices. Each tab offers a different view or tool to help you edit and visualise your project.</p>
      <p>Here's a rundown of each editor tab:</p>
      <ul>
        <li>
          <strong>Details <font-awesome-icon icon="info-circle" /></strong> -
          The details tab shows the name and description of the open project, as well as some basic insights such as the number of words and languages defined by your code. You can edit the name and description of the project in this tab.
        </li>
        <li>
          <strong>Code <font-awesome-icon icon="code" /></strong> -
          The code pane contains a text editor, where you can write the code that defines your language(s), words, and phonological rules. We'll use the code editor later in this guide to start defining a basic language.
        </li>
        <li>
          <strong>Lexicon <font-awesome-icon icon="book" /></strong> -
          This tab is where you can view all of the words generated by your definition code. Words in the lexicon <em>automatically</em> have the relevant sound changes applied to them, and update as soon as you modify the rules that affect those words.
        </li>
        <li>
          <strong>Tree <font-awesome-icon icon="sitemap" /></strong> -
          The tree tab shows the family tree of all the languages defined in your code.
        </li>
      </ul>
    </section>
    <section id="languages-and-milestones">
      <h3>5. Languages &amp; Milestones</h3>
      <p>One of the key features of Conlang Workbench is the fact that you can define and manage several related languages within the same project, as well as specific points on the timeline.</p>
      <p>It is recommended that all projects start with a timeline milestone, to which all following milestones relate. For this guide, let's mark the year zero, using the <code>@</code> symbol:</p>
      <code><pre>
        <div class="added">@ 0</div>
      </pre></code>
      <p>We can define a language by starting a line with a "plus" symbol, <code>+</code>, followed by a unique identifier for that language. The identifier should only contain alphanumeric characers and dots, like so:</p>
      <code><pre>
        <div>@ 0</div>
        <div class="added">+ my.language</div>
      </pre></code>
      <p>Selecting the "Tree" tab now, you should see that the language as been defined, but it's all alone in the family tree! For this guide, we'll only use one language, but you can check out the <router-link to="/docs/languages">Languages Guide</router-link> to explore this feature in more depth.</p>
    </section>
    <section id="words">
      <h3>6. Defining a word</h3>
      <p>Now that we have defined a language and set a point on the timeline, we can start defining the words of our language. To define a word, we start a new line of code with the "minus" symbol, <code>-</code>, followed by a unique identifier for the word, or "gloss", the phonemic transcription of the word, and an (optional) definition.</p>
      <p>The phonetic transcription, is how we tell the workbench what the word sounds like. We use the <ext-link href="https://en.wikipedia.org/wiki/International_Phonetic_Alphabet">International Phonetic Alphabet (IPA)</ext-link> to represent the different sounds in the word, and separate each syllable with a full stop (<code>.</code>). The apostrophe (<code>'</code>) is used to signify that the first syllable of the word is <ext-link href="https://en.wikipedia.org/wiki/Stress_(linguistics)">stressed</ext-link>.</p>
      <code><pre>
        <div>@ 0</div>
        <div>+ my.language</div>
        <div></div>
        <div class="added">- toki : ['to.ki] : n. language, speech</div>
      </pre></code>
      <p>After defining the word, it'll appear in the lexicon view.</p>
      <p>So that we don't have to keep switching between tabs, let's use the <strong>Split View <font-awesome-icon icon="columns" /></strong> button to show the current tab side-by-side with the code editor.</p>
    </section>
    <section id="rules">
      <h3>7. Defining Sound Changes</h3>
      <p>In our definition code, we can describe sound changes using phonological rules that will automatically be applied to the appropriate words. These rules follow a format similar to that used in real-world linguistics.</p>
      <p>To define a rule, we start a new line with the dollar symbol, <code>$</code>, followed by a <em>source</em>, a <em>target</em> and a <em>condition</em>.</p>
      <p>The <em>source</em> is what the sound change applier uses to find places in a word where the rule can be applied, and identifies the sound(s) that are affected by the change. In this example, we'll use the velar stop, /k/ as our source.</p>
      <p>The <em>target</em> is what that replaces the source. In this rule, we'll replace /k/ with the palatal stop, /c/.</p>
      <p>Finally, the <em>condition</em> is how the SCA chooses whether or not the rule should be applied to the source. In this case, the change /k/ > /c/ will only occur immediately before a close vowel, like /i/ or /u/.</p>
      <code><pre>
        <div>@ 0</div>
        <div>+ my.language</div>
        <div></div>
        <div>- toki : ['to.ki] : n. language, speech</div>
        <div></div>
        <div class="added">$ [k] > [c] / _[V+close]</div>
      </pre></code>
      <p>Once we define this rule, the lexicon is immediately updated, because the rule applies to the word we defined previously. The word /'to.ki/ has become /'to.ci/</p>
      <section id="timeline">
        <h4>7.1. Sound Changes Over Time</h4>
        <p>In real languages, sound changes don't happen all at once, they occur spread out over long periods of time. We can use milestones to simulate this, using the timeline slider to watch the language evolve over time.</p>
        <p>Let's insert a new milestone marker before the sound change, on line 4:</p>
        <code><pre>
          <div>@ 0</div>
          <div>+ my.language</div>
          <div></div>
          <div>- toki : ['to.ki] : n. language, speech</div>
          <div></div>
          <div class="added">@ 100</div>
          <div>$ [k] > [c] / _[V+close]</div>
        </pre></code>
        <p>Now, the timeline at the bottom of the lexicon tab should show two points: 0 and 100. By dragging the slider from 0 to 100, or by clicking the labels to jump to a specific year, you can see the lexicon updating to match how the language would look at the selected milestone.</p>
      </section>
    </section>
    <section id="next-steps">
      <h3>8. Next Steps</h3>
      <p>Hopefully, this guide has taught you the basics of Conlang Workbench, and you're ready to start working on some more complex projects. Here are some articles that continue to build on the basics:</p>
      <ul>
        <li><router-link to="/docs/cheat-sheet">Definition Language Cheat Sheet</router-link></li>
        <li><a href="#"><s>Languages &amp; Milestones Reference</s></a> (Coming soon.)</li>
        <li><a href="#"><s>Phonological Rules Reference</s></a> (Coming soon.)</li>
      </ul>
      <p style="font-weight: 800; margin-top: 1rem;">If you'd like to take part in the public user testing survey, you can follow this link: <ext-link href="https://plymouth.onlinesurveys.ac.uk/conlang-workbench-usability-testing." /></p>
      <p>The survey covers this user guide, and asks you to complete a few small tasks following on from the content of the guide, providing feedback along the way.</p>
    </section>
  </article>
</template>

<script>
import ExtLink from '@/components/ExtLink.vue'
import DocsNav from '@/components/DocsNav.vue'

export default {
  name: 'GettingStarted',
  components: { ExtLink, DocsNav },
}
</script>
